import React from "react"
import { motion } from "framer-motion";
import Slider from "./slider";
import { StaticImage } from "gatsby-plugin-image"
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const Plan = () => {

    const { width } = useWindowDimensions()

    const marqueeVariants = {
        animate: {
            x: [0, width > 768 ? -2000:-1000, 0],
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: width > 768 ? 30:15,
                    ease: "linear",
                },
            },
        },
    };

    const fadeInVariant = {
        initial: {
            opacity: 0,
            y: -20
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.65,
                delay: 0.25,
                type: "tween"
            }
        }
    }

    return(
        <>
            <section id="interior">
                <div className="container mx-auto">
                    <motion.div variants={fadeInVariant} initial="initial" whileInView="animate" viewport={{ once: true, margin: "-100px 0px 0px 0px" }} className="pt-16 md:pt-24 pb-12 px-4">
                        <h3 className="text-[40px] md:text-5xl font-playfair tracking-tight leading-[45px] font-medium text-center mb-3">
                            Central@Gurney
                            <span className="text-primary block italic font-normal">At the Epicentre </span>
                        </h3>
                        <p className="md:w-2/5 mx-auto text-gray-500 font-light text-[15px] text-center tracking-wide">
                            Refined yet timeless architecture, with impactful shopfronts that are designed for versatility. Marrying form and functionality, be a part of the pulse of the community at Central@Gurney, perfect for every mood and occasion.
                        </p>
                    </motion.div>
                </div>

                <div className="absolute top-[180px] -right-20 -z-1 hidden md:block">
                    <div className="border-[3px] border-primary rounded-full w-72 h-72 xl:w-96 xl:h-96"></div>
                </div>

                <Slider/>
                
                <div className="w-auto overflow-hidden pt-10 pb-4 md:pb-16 md:pt-8 relative">
                    <motion.div 
                        className="h-20"
                        variants={marqueeVariants}
                        animate="animate"
                    >
                        <div className="font-playfair text-4xl md:text-7xl tracking-tight whitespace-nowrap absolute text-gray-400 md:text-gray-700">
                            50,000 sqft nett lettable area • Shoplots • Kiosk • AI Frescos • 
                            50,000 sqft nett lettable area • Shoplots • Kiosk • AI Frescos
                        </div>
                    </motion.div>
                </div>
            </section>
            <section id="plan">
                <div className="bg-dark md:h-screen">
                    <div className="flex flex-row flex-wrap h-full justify-center gap-3">
                        <div className="w-full py-16 md:py-8 md:flex-1 md:h-full flex flex-col justify-center items-center md:order-2">
                            <div className="w-3/4 xl:w-4/5 mb-8">
                                <div className="relative w-full">
                                    <img src="/all-plan-3.jpg" alt="all-plan"></img>
                                    <a href="/all-plan-3.jpg" aria-label="Plan" target="_blank" className="absolute w-full h-full top-0">{' '}</a>
                                </div>
                            </div>
                            <h3 className="text-5xl font-playfair tracking-tight leading-[45px] font-medium text-center mb-5 text-white">
                                Retail
                                <span className="text-primary block italic font-normal">Layout Plan</span>
                            </h3>
                            <p className="text-white text-center w-2/3 text-opacity-70 font-light tracking-wide text-[15px] mb-6">The right location with an assortment of spaces that curate a modern lifestyle experience. </p>
                            {/* <div className="md:w-2/3 flex flex-col">
                                <a href="/renovation-area-tabulation.pdf" target="_blank" className="text-white hover:text-primary underline text-center text-base font-light transition-basic">
                                    Download Renovation Area Tabulation
                                </a>
                            </div> */}
                        </div>
                        <div className="w-3/4 sm:w-2/5 md:flex-1 md:border-r border-gray-200 border-opacity-10 md:h-full md:order-1 mb-16 md:mb-8">
                            <div className="flex flex-col justify-center items-center md:h-full">
                                <p className="uppercase text-white text-opacity-50 tracking-widest mb-3">Ground Floor</p>
                                <div className="w-3/4 xl:w-3/5 aspect-[3/3.5] mb-5 p-3">
                                    <StaticImage 
                                        src="../../../images/plan/ground-floor-plan.png"
                                        layout="fullWidth"
                                        quality={45}
                                        formats={["auto", "webp", "avif"]}
                                        alt=""
                                        placeholder="none"
                                    ></StaticImage>
                                </div>
                                <a href="/ground-floor-plan-3.pdf" target="_blank" className="bg-white bg-opacity-10 hover:bg-primary hover:text-white transition-basic px-10 py-3 uppercase text-white text-opacity-50 tracking-widest mb-4 text-center">Download</a>
                                {/* <div className="md:w-2/3 flex flex-col">
                                    <a href="/ground-floor-plan-2.pdf" target="_blank" className="bg-white bg-opacity-10 hover:bg-primary hover:text-white transition-basic px-10 py-3 uppercase text-white text-opacity-50 tracking-widest mb-4 text-center">Download</a>
                                    <a href="/renovation-area-tabulation.xls" target="_blank" className="bg-white bg-opacity-10 hover:bg-primary hover:text-white transition-basic px-10 py-3 uppercase text-white text-opacity-50 tracking-widest mb-4 text-center">Download Renovation Area Tabulation</a>
                                </div> */}
                            </div>
                        </div>
                        <div className="w-3/4 sm:w-2/5 md:flex-1 md:border-l border-gray-200 border-opacity-10 md:h-full md:order-3 mb-8">
                            <div className="flex flex-col justify-center items-center md:h-full">
                                <p className="uppercase text-white text-opacity-50 tracking-widest mb-3">First Floor</p>
                                <div className="w-3/4 xl:w-3/5 aspect-[3/3.5] mb-5 p-3">
                                    <StaticImage 
                                        src="../../../images/plan/first-floor-plan.png"
                                        layout="fullWidth"
                                        quality={45}
                                        formats={["auto", "webp", "avif"]}
                                        alt=""
                                        placeholder="none"
                                    ></StaticImage>
                                </div>
                                <a href="/first-floor-plan-3.pdf" target="_blank" className="bg-white bg-opacity-10 hover:bg-primary hover:text-white transition-basic px-10 py-3 uppercase text-white text-opacity-50 tracking-widest mb-4 text-center">Download</a>
                                {/* <div className="md:w-2/3 flex flex-col">
                                    <a href="/first-floor-plan-2.pdf" target="_blank" className="bg-white bg-opacity-10 hover:bg-primary hover:text-white transition-basic px-10 py-3 uppercase text-white text-opacity-50 tracking-widest mb-4 text-center">Download</a>
                                    <a href="/renovation-area-tabulation.xls" target="_blank" className="bg-white bg-opacity-10 hover:bg-primary hover:text-white transition-basic px-10 py-3 uppercase text-white text-opacity-50 tracking-widest mb-4 text-center">Download Renovation Area Tabulation</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}




export default Plan;

import React from "react"
import { SplitText } from "../splitText";
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby";

const About = () => {

    const imageProps = {
        layout:"fullWidth",
        quality:45,
        formats:["auto", "webp", "avif"],
        alt:"",
        placeholder:"none",
        transformOptions:{ fit: 'contain' },
    }

    return(
        <section id="about" className="bg-dark z-30 min-h-screen">
            <div className="container mx-auto">
                <div className="md:w-1/2 xl:w-2/5 px-4 mx-auto pt-24 pb-16">
                    <h3 className="text-5xl font-playfair tracking-tight leading-[45px] font-medium text-center md:mb-3 text-white">
                        The Heart of the
                        <span className="text-primary block italic font-normal">Vibrant Penang</span>
                    </h3>
                    <p className="text-white text-opacity-90 text-center leading-loose font-light">
                        <SplitText
                            initial={{ scale: 0.95, filter: 'blur(2px)', opacity: 0, y: 0 }}
                            whileInView="animate"
                            variants={{
                                animate: i => ({
                                    scale: 1,
                                    filter: 'blur(0px)',
                                    opacity: 1,
                                    y: 10,
                                    transition: {
                                        duration: 0.24,
                                        delay: 0.15+(i*0.005),
                                    }
                                })
                            }}
                        >
                            Located at the core of Penang Island, heralded as one of Malaysia’s most fascinating cities with world-acclaimed food, rich history, diverse cultures, and scenic beaches is Central@Gurney. Serving as the new nexus of cuisine, entertainment and leisure, Central@Gurney melds avant-garde designs with thoughtful comfort. Home to an eclectic mix of restaurants, bistros and cafes, tourists and locals alike are drawn to dine and unwind with a panoramic view of the seafront promenade of the iconic Gurney Drive.
                        </SplitText>
                    </p>
                </div>
            </div>
            <div className="px-16 py-10">
                <p className="text-white text-opacity-50 uppercase font-playfair tracking-widest px-4 text-center">
                    Welcome On Board
                </p>
                <div className="flex flex-row flex-wrap py-6 gap-12 justify-center items-center">
                    <div className="w-2/6 md:w-56 relative">
                        <Link to="/tenant/winter-warmer" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                        <StaticImage src="../../images/brand/brand-1.png" className="transition-basic hover:opacity-50 filter brightness-120 cursor-pointer" {...imageProps}></StaticImage>
                    </div>
                    <div className="w-2/6 md:w-36 relative">
                        <Link to="/tenant/the-loft" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                        <StaticImage src="../../images/brand/brand-7.png" className="transition-basic hover:opacity-50 cursor-pointer" {...imageProps}></StaticImage>
                    </div>
                    <div className="w-2/6 md:w-60 relative md:-mx-8">
                        <Link to="/tenant/the-holy-guacamole" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                        <StaticImage src="../../images/brand/brand-6-2.png" className="transition-basic hover:opacity-50 cursor-pointer" {...imageProps}></StaticImage>
                    </div>
                    <div className="w-2/6 md:w-40 relative">
                        <Link to="/tenant/halzan-by-wheelers" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                        <StaticImage src="../../images/brand/brand-4-1.png" className="transition-basic hover:opacity-50 cursor-pointer" {...imageProps}></StaticImage>
                    </div>
                    
                </div>
                <div className="flex flex-row flex-wrap py-6 gap-12 justify-center items-center">
                    <div className="w-2/6 md:w-48 relative">
                        <Link to="/tenant/antipodean" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                        <StaticImage src="../../images/brand/brand-10.png" className="transition-basic hover:opacity-50 cursor-pointer" {...imageProps}></StaticImage>
                    </div>
                    <div className="w-2/6 md:w-36 relative">
                        <Link to="/tenant/coffee-bean" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                        <StaticImage src="../../images/brand/brand-2.png" className="transition-basic hover:opacity-50 filter invert cursor-pointer" {...imageProps}></StaticImage>
                    </div>
                    <div className="w-2/6 md:w-36 relative">
                        <Link to="/tenant/kanada-ya" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                        <StaticImage src="../../images/brand/brand-5-2.png" className="transition-basic hover:opacity-50 cursor-pointer" {...imageProps}></StaticImage>
                    </div>
                    <div className="w-2/6 md:w-48 relative">
                        <Link to="/tenant/tree-bar" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                        <StaticImage src="../../images/brand/brand-8.png" className="transition-basic hover:opacity-50 cursor-pointer" {...imageProps}></StaticImage>
                    </div>
                   
                </div>
                <div className="flex flex-row flex-wrap py-6 gap-12 justify-center items-center">
                    <div className="w-4/12 md:w-24 relative">
                        <Link to="/tenant/miraku" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                        <StaticImage src="../../images/brand/brand-3-2.png" className="transition-basic hover:opacity-50 cursor-pointer" {...imageProps}></StaticImage>
                    </div>
                    <div className="w-4/12 md:w-36 relative">
                        <Link to="/tenant/richiamo" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                        <StaticImage src="../../images/brand/brand-9.png" className="transition-basic hover:opacity-50 cursor-pointer" {...imageProps}></StaticImage>
                    </div>
                    <div className="w-8/12 md:w-48 relative">
                        <Link to="/tenant/ayataya" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                        <StaticImage src="../../images/brand/brand-11.png" className="transition-basic hover:opacity-50 invert cursor-pointer brightness-0" {...imageProps}></StaticImage>
                    </div>
                    <div className="w-4/12 md:w-48 relative">
                        <Link to="/tenant/chilis" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                        <StaticImage src="../../images/brand/brand-13.png" className="transition-basic hover:opacity-50 cursor-pointer" {...imageProps}></StaticImage>
                    </div>
                </div>
                <div className="flex flex-row flex-wrap py-6 gap-12 justify-center items-center">
                    <div className="w-full -mt-16 md:w-80 md:-mx-20 md:mt-0 relative">
                        <Link to="/tenant/minimelts" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                        <StaticImage src="../../images/brand/brand-12.png" className="transition-basic hover:opacity-50 cursor-pointer" {...imageProps}></StaticImage>
                    </div>
                    <div className="w-2/5 md:w-52 relative">
                        <Link to="/tenant/dipndip" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                        <StaticImage src="../../images/brand/brand-14.png" className="transition-basic hover:opacity-50 cursor-pointer" {...imageProps}></StaticImage>
                    </div>
                </div>
                <div className="my-3 w-full flex">
                    <Link to="/tenant" className="bg-primary hover:bg-opacity-75 hover:text-white transition-basic px-10 py-3 uppercase text-white tracking-widest inline-block mx-auto text-sm">Learn More</Link>
                </div>
                {/* <div className="mt-16 mb-8">
                    <p className="text-white text-opacity-50 uppercase font-playfair tracking-widest px-4 text-center">
                        Coming Soon
                    </p>
                    <div className="flex flex-row flex-wrap py-6 gap-4 justify-center items-center">
                        <div className="w-2/5 md:w-52 relative">
                            <Link to="/tenant/dipndip" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                            <StaticImage src="../../images/brand/brand-14.png" className="transition-basic hover:opacity-50 cursor-pointer" {...imageProps}></StaticImage>
                        </div>
                    </div>
                </div> */}
                
            </div>
        </section>
    )
}

export default About
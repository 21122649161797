import React, { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode, Pagination } from "swiper";
import { StaticImage } from "gatsby-plugin-image"

import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";

const itemProps = {
    layout: "fullWidth",
    quality: 45,
    formats: ["auto", "webp", "avif"],
    placeholder: "blurred",
    className: "h-full"
}

const MonthImage = ({month}) => {
    const item = "image"
    switch(month){
        case "April 2022":
            return <StaticImage src={`../../images/hoarding/front-3.jpg`} alt={item} {...itemProps}/>
        case "May 2022":
            return <StaticImage src={`../../images/progress/may-3.jpg`} alt={item} {...itemProps}/>
        case "June 2022":
            return <StaticImage src={`../../images/progress/june-3.jpg`} alt={item} {...itemProps}/>
        case "July 2022":
            return <StaticImage src={`../../images/progress/july-3.jpg`} alt={item} {...itemProps}/>
        case "August 2022":
            return <StaticImage src={`../../images/progress/aug-3.jpg`} alt={item} {...itemProps}/>
        case "September 2022":
            return <StaticImage src={`../../images/progress/sep-1.jpg`} alt={item} {...itemProps}/>
        case "October 2022":
            return <StaticImage src={`../../images/progress/oct-1.jpg`} alt={item} {...itemProps}/>
        case "November 2022":
            return <StaticImage src={`../../images/progress/nov-1.jpg`} alt={item} {...itemProps}/>
        case "December 2022":
            return <StaticImage src={`../../images/progress/dec-1.jpg`} alt={item} {...itemProps}/>
        case "January 2023":
            return <StaticImage src={`../../images/progress/jan-2023-1.jpg`} alt={item} {...itemProps}/>
        case "February 2023":
            return <StaticImage src={`../../images/progress/feb-2023-1.jpg`} alt={item} {...itemProps}/>
        case "March 2023":
            return <StaticImage src={`../../images/progress/mar-2023-1.jpg`} alt={item} {...itemProps}/>
        case "April 2023":
            return <StaticImage src={`../../images/progress/apr-2023-1.jpg`} alt={item} {...itemProps}/>
        case "May 2023":
            return <StaticImage src={`../../images/progress/may-2023-1.jpg`} alt={item} {...itemProps}/>
        case "June 2023":
            return <StaticImage src={`../../images/progress/jun-2023-1.jpg`} alt={item} {...itemProps}/>
        case "July 2023":
            return <StaticImage src={`../../images/progress/jul-2023-1.jpg`} alt={item} {...itemProps}/>
        case "August 2023":
            return <StaticImage src={`../../images/progress/aug-2023-1.jpg`} alt={item} {...itemProps}/>
        case "September 2023":
            return <StaticImage src={`../../images/progress/sep-2023-1.png`} alt={item} {...itemProps}/>
        case "October 2023":
            return <StaticImage src={`../../images/progress/oct-2023-1.jpg`} alt={item} {...itemProps}/>
        case "November 2023":
            return <StaticImage src={`../../images/progress/nov-2023-1.jpeg`} alt={item} {...itemProps}/>
        case "December 2023":
            return <StaticImage src={`../../images/progress/dec-2023-1.jpeg`} alt={item} {...itemProps}/>
        case "January 2024":
            return <StaticImage src={`../../images/progress/jan-2023-1.jpg`} alt={item} {...itemProps}/>
        case "February 2024":
            return <StaticImage src={`../../images/progress/feb-2023-1.jpg`} alt={item} {...itemProps}/>
        case "March 2024":
            return <StaticImage src={`../../images/progress/mar-2024-1.jpg`} alt={item} {...itemProps}/>
        case "April 2024":
            return <StaticImage src={`../../images/progress/apr-2024-1.jpg`} alt={item} {...itemProps}/>
        case "May 2024":
            return <StaticImage src={`../../images/progress/may-2024-1.jpg`} alt={item} {...itemProps}/>
        case "June 2024":
            return <StaticImage src={`../../images/progress/june-2024-1.jpg`} alt={item} {...itemProps}/>
        default:
            return <StaticImage src={`../../images/progress/july-2024-1.jpg`} alt={item} {...itemProps}/>
    }
}

const ProgressImage = ({index}) => {
    const item = "image";
    switch(index){
        case 1:
            return <StaticImage src={`../../images/progress/entrance.jpg`} alt={item} {...itemProps}/>
        case 2:
            return <StaticImage src={`../../images/progress/front-top.jpg`} alt={item} {...itemProps} />
        case 3:
            return <StaticImage src={`../../images/progress/front.jpg`} alt={item} {...itemProps}/>
        case 4:
            return <StaticImage src={`../../images/progress/back.jpg`} alt={item} {...itemProps}/>
        case 5:
            return <StaticImage src={`../../images/hoarding/front-1.jpeg`} alt={item} {...itemProps}/>
        case 6:
            return <StaticImage src={`../../images/hoarding/front-2.jpeg`} alt={item} {...itemProps}/>
        case 7:
            return <StaticImage src={`../../images/hoarding/front-3.jpg`} alt={item} {...itemProps}/>
        case 8:
            return <StaticImage src={`../../images/hoarding/side-1.jpeg`} alt={item} {...itemProps}/>
        case 9:
            return <StaticImage src={`../../images/hoarding/side-2.jpeg`} alt={item} {...itemProps}/>
        case 10:
            return <StaticImage src={`../../images/hoarding/side-3.jpeg`} alt={item} {...itemProps}/>
        case 11:
            return <StaticImage src={`../../images/hoarding/back-1.jpeg`} alt={item} {...itemProps}/>
        case 12:
            return <StaticImage src={`../../images/hoarding/back-2.jpg`} alt={item} {...itemProps}/>
        case 13:
            return <StaticImage src={`../../images/hoarding/back-3.jpeg`} alt={item} {...itemProps}/>
        case 14:
            return <StaticImage src={`../../images/progress/may-1.jpg`} alt={item} {...itemProps}/>
        case 15:
            return <StaticImage src={`../../images/progress/may-2.jpg`} alt={item} {...itemProps}/>
        case 16:
            return <StaticImage src={`../../images/progress/may-3.jpg`} alt={item} {...itemProps}/>
        case 17:
            return <StaticImage src={`../../images/progress/june-1.jpg`} alt={item} {...itemProps}/>
        case 18:
            return <StaticImage src={`../../images/progress/june-2.jpg`} alt={item} {...itemProps}/>
        case 19:
            return <StaticImage src={`../../images/progress/june-3.jpg`} alt={item} {...itemProps}/>
        case 20:
            return <StaticImage src={`../../images/progress/july-1.jpg`} alt={item} {...itemProps}/>
        case 21:
            return <StaticImage src={`../../images/progress/july-2.jpg`} alt={item} {...itemProps}/>
        case 22:
            return <StaticImage src={`../../images/progress/july-3.jpg`} alt={item} {...itemProps}/>
        case 23:
            return <StaticImage src={`../../images/progress/aug-1.jpg`} alt={item} {...itemProps}/>
        case 24:
            return <StaticImage src={`../../images/progress/aug-2.jpg`} alt={item} {...itemProps}/>
        case 25:
            return <StaticImage src={`../../images/progress/aug-3.jpg`} alt={item} {...itemProps}/>
        case 26:
            return <StaticImage src={`../../images/progress/sep-1.jpg`} alt={item} {...itemProps}/>
        case 27:
            return <StaticImage src={`../../images/progress/sep-2.jpg`} alt={item} {...itemProps}/>
        case 28:
            return <StaticImage src={`../../images/progress/sep-3.jpg`} alt={item} {...itemProps}/>
        case 29:
            return <StaticImage src={`../../images/progress/oct-1.jpg`} alt={item} {...itemProps}/>
        case 30:
            return <StaticImage src={`../../images/progress/oct-2.jpg`} alt={item} {...itemProps}/>
        case 31:
            return <StaticImage src={`../../images/progress/oct-3.jpg`} alt={item} {...itemProps}/>
        case 32:
            return <StaticImage src={`../../images/progress/nov-1.jpg`} alt={item} {...itemProps}/>
        case 33:
            return <StaticImage src={`../../images/progress/nov-2.jpg`} alt={item} {...itemProps}/>
        case 34:
            return <StaticImage src={`../../images/progress/nov-3.jpg`} alt={item} {...itemProps}/>
        case 35:
            return <StaticImage src={`../../images/progress/dec-1.jpg`} alt={item} {...itemProps}/>
        case 36:
            return <StaticImage src={`../../images/progress/dec-2.jpg`} alt={item} {...itemProps}/>
        case 37:
            return <StaticImage src={`../../images/progress/dec-3.jpg`} alt={item} {...itemProps}/>
        case 38:
            return <StaticImage src={`../../images/progress/jan-2023-1.jpg`} alt={item} {...itemProps}/>
        case 39:
            return <StaticImage src={`../../images/progress/jan-2023-2.jpg`} alt={item} {...itemProps}/>
        case 40:
            return <StaticImage src={`../../images/progress/jan-2023-3.jpg`} alt={item} {...itemProps}/>
        case 41:
            return <StaticImage src={`../../images/progress/feb-2023-1.jpg`} alt={item} {...itemProps}/>
        case 42:
            return <StaticImage src={`../../images/progress/feb-2023-2.jpg`} alt={item} {...itemProps}/>
        case 43:
            return <StaticImage src={`../../images/progress/feb-2023-3.jpg`} alt={item} {...itemProps}/>
        case 44:
            return <StaticImage src={`../../images/progress/mar-2023-1.jpg`} alt={item} {...itemProps}/>
        case 45:
            return <StaticImage src={`../../images/progress/mar-2023-2.jpg`} alt={item} {...itemProps}/>
        case 46:
            return <StaticImage src={`../../images/progress/mar-2023-3.jpg`} alt={item} {...itemProps}/>
        case 47:
            return <StaticImage src={`../../images/progress/apr-2023-1.jpg`} alt={item} {...itemProps}/>
        case 48:
            return <StaticImage src={`../../images/progress/apr-2023-2.jpg`} alt={item} {...itemProps}/>
        case 49:
            return <StaticImage src={`../../images/progress/apr-2023-3.jpg`} alt={item} {...itemProps}/>
        case 50:
            return <StaticImage src={`../../images/progress/may-2023-1.jpg`} alt={item} {...itemProps}/>
        case 51:
            return <StaticImage src={`../../images/progress/may-2023-2.jpg`} alt={item} {...itemProps}/>
        case 52:
            return <StaticImage src={`../../images/progress/may-2023-3.jpg`} alt={item} {...itemProps}/>
        case 53:
            return <StaticImage src={`../../images/progress/jun-2023-1.jpg`} alt={item} {...itemProps}/>
        case 54:
            return <StaticImage src={`../../images/progress/jun-2023-2.jpg`} alt={item} {...itemProps}/>
        case 55:
            return <StaticImage src={`../../images/progress/jun-2023-3.jpg`} alt={item} {...itemProps}/>
        case 56:
            return <StaticImage src={`../../images/progress/jul-2023-1.jpg`} alt={item} {...itemProps}/>
        case 57:
            return <StaticImage src={`../../images/progress/jul-2023-2.png`} alt={item} {...itemProps}/>
        case 58:
            return <StaticImage src={`../../images/progress/jul-2023-3.jpg`} alt={item} {...itemProps}/>
        case 59:
            return <StaticImage src={`../../images/progress/aug-2023-1.jpg`} alt={item} {...itemProps}/>
        case 60:
            return <StaticImage src={`../../images/progress/aug-2023-2.jpeg`} alt={item} {...itemProps}/>
        case 61:
            return <StaticImage src={`../../images/progress/aug-2023-3.jpeg`} alt={item} {...itemProps}/>
        case 62:
            return <StaticImage src={`../../images/progress/sep-2023-1.png`} alt={item} {...itemProps}/>
        case 63:
            return <StaticImage src={`../../images/progress/sep-2023-2.png`} alt={item} {...itemProps}/>
        case 64:
            return <StaticImage src={`../../images/progress/sep-2023-3.png`} alt={item} {...itemProps}/>
        case 65:
            return <StaticImage src={`../../images/progress/oct-2023-1.jpg`} alt={item} {...itemProps}/>
        case 66:
            return <StaticImage src={`../../images/progress/oct-2023-2.jpg`} alt={item} {...itemProps}/>
        case 67:
            return <StaticImage src={`../../images/progress/oct-2023-3.jpg`} alt={item} {...itemProps}/>
        case 68:
            return <StaticImage src={`../../images/progress/nov-2023-1.jpeg`} alt={item} {...itemProps}/>
        case 69:
            return <StaticImage src={`../../images/progress/nov-2023-2.jpeg`} alt={item} {...itemProps}/>
        case 70:
            return <StaticImage src={`../../images/progress/nov-2023-3.jpeg`} alt={item} {...itemProps}/>
        case 71:
            return <StaticImage src={`../../images/progress/dec-2023-1.jpeg`} alt={item} {...itemProps}/>
        case 72:
            return <StaticImage src={`../../images/progress/dec-2023-2.jpeg`} alt={item} {...itemProps}/>
        case 73:
            return <StaticImage src={`../../images/progress/dec-2023-3.jpeg`} alt={item} {...itemProps}/>
        case 74:
            return <StaticImage src={`../../images/progress/jan-2024-1.jpg`} alt={item} {...itemProps}/>
        case 75:
            return <StaticImage src={`../../images/progress/jan-2024-2.jpg`} alt={item} {...itemProps}/>
        case 76:
            return <StaticImage src={`../../images/progress/jan-2024-3.jpg`} alt={item} {...itemProps}/>
        case 77:
            return <StaticImage src={`../../images/progress/feb-2024-1.jpeg`} alt={item} {...itemProps}/>
        case 78:
            return <StaticImage src={`../../images/progress/feb-2024-2.jpeg`} alt={item} {...itemProps}/>
        case 79:
            return <StaticImage src={`../../images/progress/feb-2024-3.jpg`} alt={item} {...itemProps}/>
        case 80:
            return <StaticImage src={`../../images/progress/mar-2024-1.jpg`} alt={item} {...itemProps}/>
        case 81:
            return <StaticImage src={`../../images/progress/mar-2024-2.jpg`} alt={item} {...itemProps}/>
        case 82:
            return <StaticImage src={`../../images/progress/mar-2024-3.jpg`} alt={item} {...itemProps}/>
        case 83:
            return <StaticImage src={`../../images/progress/apr-2024-1.jpg`} alt={item} {...itemProps}/>
        case 84:
            return <StaticImage src={`../../images/progress/apr-2024-2.jpg`} alt={item} {...itemProps}/>
        case 85:
            return <StaticImage src={`../../images/progress/apr-2024-3.jpg`} alt={item} {...itemProps}/>
        case 86:
            return <StaticImage src={`../../images/progress/may-2024-1.jpg`} alt={item} {...itemProps}/>
        case 87:
            return <StaticImage src={`../../images/progress/may-2024-2.jpg`} alt={item} {...itemProps}/>
        case 88:
            return <StaticImage src={`../../images/progress/may-2024-3.jpeg`} alt={item} {...itemProps}/>
        case 89:
            return <StaticImage src={`../../images/progress/june-2024-1.jpg`} alt={item} {...itemProps}/>
        case 90:
            return <StaticImage src={`../../images/progress/june-2024-2.jpg`} alt={item} {...itemProps}/>
        case 91:
            return <StaticImage src={`../../images/progress/june-2024-3.jpg`} alt={item} {...itemProps}/>
        case 92:
            return <StaticImage src={`../../images/progress/july-2024-1.jpg`} alt={item} {...itemProps}/>
        case 93:
            return <StaticImage src={`../../images/progress/july-2024-2.jpg`} alt={item} {...itemProps}/>
        case 94:
            return <StaticImage src={`../../images/progress/july-2024-3.png`} alt={item} {...itemProps}/>
        default:
            return <StaticImage src={`../../images/progress/apr-2024-3.jpg`} alt={item} {...itemProps}/>
    }
}
const Progress = () => {

    const [modal, setModal] = useState(null) //month
    const [images, setImages] = useState([])

    useEffect(()=>{
        const array = {
            "April 2022": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
            "May 2022": [14, 15, 16],
            "June 2022": [17, 18, 19],
            "July 2022": [20, 21, 22],
            "August 2022": [23, 24, 25],
            "September 2022": [26, 27, 28],
            "October 2022": [29, 30, 31],
            "November 2022": [32, 33, 34],
            "December 2022": [35, 36, 37],
            "January 2023": [38, 39, 40],
            "February 2023": [41, 42, 43],
            "March 2023": [44, 45, 46],
            "April 2023": [47, 48, 49],
            "May 2023": [50, 51, 52],
            "June 2023": [53, 54, 55],
            "July 2023": [56, 57, 58],
            "August 2023": [59, 60, 61],
            "September 2023": [62, 63, 64],
            "October 2023": [65, 66, 67],
            "November 2023": [68, 69, 70],
            "December 2023": [71, 72, 73],
            "January 2024": [74, 75, 76],
            "February 2024": [77, 78, 79],
            "March 2024": [80, 81, 82],
            "April 2024": [83, 84, 85],
            "May 2024": [86, 87, 88],
            "June 2024": [89, 90, 91],
            "July 2024": [92, 93, 94],
        }

        if(modal){
            setImages(array[modal])
        }
    },[modal])

    const sliderArray = [
        "April 2022",
        "May 2022",
        "June 2022",
        "July 2022",
        "August 2022",
        "September 2022",
        "October 2022",
        "November 2022",
        "December 2022",
        "January 2023",
        "February 2023",
        "March 2023",
        "April 2023",
        "May 2023",
        "June 2023",
        "July 2023",
        "August 2023",
        "September 2023",
        "October 2023",
        "November 2023",
        "December 2023",
        "January 2024",
        "February 2024",
        "March 2024",
        "April 2024",
        "May 2024",
        "June 2024",
        "July 2024",
    ]

    const CloseButton = () => {
        return(
            <button 
                onClick={()=>setModal(null)}
                className="absolute top-0 right-0 flex flex-col justify-center items-center w-16 h-16 ml-auto mr-6 transition-basic z-50" >
                    <div className="transition-basic transform rotate-45 translate-y-[22px] bg-white w-2/4 h-[2px] mb-5"></div>
                    <div className="transition-basic transform -rotate-45 bg-white w-2/4 h-[2px]"></div>
            </button>
        )
    }

    return(
        <>
            <div className="container mx-auto pt-10">
                <h3 className="text-[40px] md:text-5xl font-playfair tracking-tight leading-[45px] font-medium text-center mb-5">
                    Current  
                    <span className="text-primary block italic font-normal">Site Progress</span>
                </h3>
            </div>

            <Swiper
                className="swiper-interior mb-10"
                modules={[Navigation, Autoplay, FreeMode]}
                navigation={true}
                spaceBetween={16}
                slidesPerView={1.75}
                centeredSlides
                initialSlide={2}
                breakpoints={{
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 32,
                        centeredSlides: false,
                        initialSlide: 0
                    },
                }}
            >
                {sliderArray.reverse().map((item, i) => item && ( 
                    <SwiperSlide key={"interior"+item+i}>
                        <div className="aspect-[4/3] bg-gray-200 relative md:hover:scale-[1.075] transition-basic md:my-5">
                            <button 
                                className="absolute z-50 w-full h-full" 
                                onClick={()=>setModal(item)}
                            >
                                {' '}
                            </button>
                            <MonthImage month={item}/>
                            <div className="p-3 mb-2 bg-white text-dark text-center">
                                <span className="font-normal">{item}</span>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className={`modal ${modal ? 'visible opacity-100':'invisible opacity-0'} transition-basic`}>
                <CloseButton/>
                <div className="bg-black bg-opacity-75 p-3 w-full h-full flex items-center justify-center" >
                    <button onClick={()=>setModal(null)} className="absolute top-0 right-0 bottom-0 left-0 -z-1 cursor-default"> </button>
                    <div className="w-11/12 lg:w-[100vh] mx-auto relative z-50">
                        <Swiper
                            pagination={{
                                type: "fraction",
                            }}
                            loop
                            className="swiper-interior mb-10 text-white"
                            modules={[Navigation, Pagination]}
                            navigation={true}
                            spaceBetween={16}
                            slidesPerView={1}
                            centeredSlides
                            initialSlide={1}
                            breakpoints={{
                                768: {
                                    slidesPerView: 1,
                                    spaceBetween: 16,
                                    centeredSlides: false,
                                    initialSlide: 1
                                },
                            }}
                        >
                            {
                                images && images.length > 0 && images.map((item, i) => (
                                    <SwiperSlide key={"progress"+item+i}>
                                        <div className="aspect-video mb-10 mt-10">
                                            <ProgressImage index={item}/>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                </div>    
            </div>
        </>
    )
}

export default Progress;